import { Switch } from 'antd';
import styled from 'styled-components';

import { ChevronRightIcon, ProfileIcon } from 'icons';
import { APP_FONT_14PX_REGULAR, APP_FONT_16PX_REGULAR, APP_FONT_16PX_SEMIBOLD } from 'mockup-font';
import { useLocalAppProperties } from 'providers';
import { CustomColor, getUserEmail, getUserName, useCustomColor } from 'utils';

import { useAppTheme } from '../../hooks';
import { CoreStatsView } from './components/CoreStatsView';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 44px 17px;
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Circle = styled.div<{ $highlightHEX: string } & CustomColor>`
  width: 79px;
  height: 79px;
  border-radius: 50%;
  background-color: ${useCustomColor};
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  :after {
    content: '+';
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #fff;
    background-color: ${({ $highlightHEX }) => $highlightHEX};
    font-size: 18px;
    padding: 0 0 3px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledProfileIcon = styled(ProfileIcon)`
  &&&& {
    font-size: 46px;
    color: #fff;
  }
`;

const UserName = styled.div`
  ${APP_FONT_16PX_SEMIBOLD};
  margin-top: 12px;
`;

const UserEmail = styled.div`
  ${APP_FONT_14PX_REGULAR};
`;

const Divider = styled.div`
  border-top: 1px solid #cecece;
  margin: 15px 0 9px;
`;

const Heading = styled.div<CustomColor>`
  color: ${useCustomColor};
  ${APP_FONT_14PX_REGULAR};
  margin-top: 44px;
`;

const Row = styled.div`
  width: 100%;
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${APP_FONT_16PX_REGULAR};

  svg {
    font-size: 32px;
  }
`;

const Toggle = styled(Switch)<CustomColor>`
  &&&& {
    width: 45px;
    height: 29px;
    border-radius: 33px;
    box-shadow: none;
    background-color: ;
    background-color: ${({ checked }) => (checked ? '#30BF47' : useCustomColor)};
    opacity: 1;

    .ant-switch-handle {
      top: 3px;
      left: ${({ checked }) => (checked ? '19px' : '3px')};
      width: 23px;
      height: 23px;

      ::before {
        border-radius: 50%;
      }
    }
  }
`;

const SignOut = styled.div<{ $borderColor: string }>`
  margin: 52px auto 0;
  width: 143px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${APP_FONT_14PX_REGULAR};
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  border-radius: 4px;
`;

const VersionLabel = styled.div<CustomColor>`
  color: ${useCustomColor};
  ${APP_FONT_14PX_REGULAR};
  margin-top: 34px;
  text-align: center;
`;

const Spacer = styled.div`
  height: 70px;
`;

export const ProfileView = () => {
  const { properties } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const HighlightHEX = getDesignProperty('highlightHEX') as string;
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const SecondaryItemHEX = getDesignProperty('secondaryItemHEX') as string;
  const BackgroundItemHEX = getDesignProperty('backgroundItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const DisabledItemHEX = getDesignProperty('disabledItemHEX') as string;

  const coreStatsConfig = properties.CoreStats ? JSON.parse(properties.CoreStats) : { Enabled: '0' };
  const coreStatsIsEnabled = properties.DisplayCoreStats === '1' && coreStatsConfig.Enabled === '1';

  const versionLabel = properties?.HideVidAppInVersionLabel === '1' ? 'App Version X.X.X' : 'VidApp X.X.X';

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      <UserSection>
        <Circle $color={BackgroundItemHEX} $highlightHEX={HighlightHEX}>
          <StyledProfileIcon />
        </Circle>
        <UserName>{getUserName() ?? 'John Smith'}</UserName>
        <UserEmail>{getUserEmail() ?? 'johnsmith@email.com'}</UserEmail>
      </UserSection>
      {coreStatsIsEnabled && <CoreStatsView />}
      <Divider />
      <Heading $color={SecondaryItemHEX}>Settings</Heading>
      {[
        { label: 'Enable My Core Stats', isEnabled: true, isHidden: !coreStatsIsEnabled },
        { label: 'Auto play', isEnabled: true },
        { label: 'Only download via wifi', isEnabled: true },
        { label: 'Continue video in background' },
        { label: 'Allow background audio from other apps' },
      ].map(
        ({ label, isEnabled, isHidden }) =>
          !isHidden && (
            <Row key={label}>
              {label}
              <Toggle checked={isEnabled} disabled $color={DisabledItemHEX} />
            </Row>
          ),
      )}
      <Row>
        Download quality
        <ChevronRightIcon />
      </Row>
      <Heading $color={SecondaryItemHEX}>Other</Heading>
      {['Terms & Conditions', 'Privacy Policy', 'Support', 'Request Data Deletion'].map((label) => (
        <Row key={label}>
          {label}
          <ChevronRightIcon />
        </Row>
      ))}
      <Divider />
      <SignOut $borderColor={DisabledItemHEX}>Sign Out</SignOut>
      <VersionLabel $color={SecondaryItemHEX}>{versionLabel}</VersionLabel>
      <Spacer />
    </Wrapper>
  );
};
