import { Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { useAppBeingEdited } from 'app-context';
import { useLocalAppProperties, useSaveContext } from 'providers';
import { isUrl } from 'utils';

import { CoreStatsSettings } from 'app/modules/build-screens/components/CoreStatsSettings';
import { setInitialFormField } from '../../utils';

export const CoreStatsDrawerSettings = ({ isSection }: { isSection?: boolean }) => {
  const [form] = Form.useForm();

  const { setAppPropertyToSave } = useSaveContext();
  const { properties, setAppProperty } = useLocalAppProperties();
  const appId = useAppBeingEdited();

  const [coreStatsFilenames, setCoreStatsFilenames] = useState<Record<string, string | undefined>>({});
  const [initialized, setInitialized] = useState(false);

  const filePath = `https://storage.vidapp.com/${appId}/icons/`;

  // Initialize core stats fields to match local state
  useEffect(() => {
    if (properties.DisplayCoreStats === '1' && properties.CoreStats) {
      const coreStats = JSON.parse(properties.CoreStats);
      setInitialFormField(form, 'CoreStatsEnabled', coreStats.Enabled === '1');
      setInitialFormField(form, 'CoreStat1Title', coreStats.Stats[0].Title);
      setInitialFormField(form, 'CoreStat2Title', coreStats.Stats[1].Title);
      setInitialFormField(form, 'CoreStat3Title', coreStats.Stats[2].Title);

      setCoreStatsFilenames({
        CoreStat1Icon: coreStats.Stats[0].Icon,
        CoreStat2Icon: coreStats.Stats[1].Icon,
        CoreStat3Icon: coreStats.Stats[2].Icon,
      });
    }
  }, []);

  const getIconUrlToSave = (icon: string | undefined) => {
    if (icon) {
      return isUrl(icon) ? icon : `${filePath}${icon}`;
    }

    return '';
  };

  const updateCoreStats = useCallback(() => {
    const value = JSON.stringify({
      Enabled: form.getFieldValue('CoreStatsEnabled') ? '1' : '0',
      Stats: [
        { Title: form.getFieldValue('CoreStat1Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat1Icon) },
        { Title: form.getFieldValue('CoreStat2Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat2Icon) },
        { Title: form.getFieldValue('CoreStat3Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat3Icon) },
      ],
    });

    setAppProperty('CoreStats', value);
    if (initialized) {
      setAppPropertyToSave('CoreStats', value);
    }
  }, [form, initialized, coreStatsFilenames, setAppProperty, setAppPropertyToSave]);

  useEffect(() => {
    if (Object.keys(coreStatsFilenames).length > 0) {
      updateCoreStats();
      setInitialized(true);
    }
  }, [coreStatsFilenames]);

  return (
    <Form name="settings-form" autoComplete="off" form={form}>
      <CoreStatsSettings
        filenames={coreStatsFilenames}
        onToggleChange={updateCoreStats}
        onInputChange={updateCoreStats}
        onFilenameChange={(key: string, filename: string) =>
          setCoreStatsFilenames((oldState) => ({ ...oldState, [key]: filename }))
        }
        isSection={isSection}
      />
    </Form>
  );
};
