import { useCallback } from 'react';
import styled from 'styled-components';

import { S3_BUCKET } from 'api';
import { useAppBeingEdited } from 'app-context';
import { APP_FONT_10PX_MEDIUM, APP_FONT_18PX_MEDIUM } from 'mockup-font';
import { useLocalAppProperties } from 'providers';
import { TintImage, isUrl } from 'utils';

import { useAppTheme } from '../../../hooks';

interface CoreStat {
  Title: string;
  Icon: string;
}

const CoreStatsSection = styled.div<{ $isSection?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ $isSection }) => ($isSection ? '15px' : '44px')};
  margin-bottom: ${({ $isSection }) => ($isSection ? '15px' : '20px')};
`;

const CoreStatWrapper = styled.div<{ $isSection?: boolean }>`
  width: 113px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${APP_FONT_18PX_MEDIUM};
  text-align: center;
  margin-bottom: ${({ $isSection }) => ($isSection ? '0' : '17px')};
`;

const CoreStatIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 9px;
`;

const CoreStatTitle = styled.div`
  ${APP_FONT_10PX_MEDIUM};
  width: 65%;
  margin-top: 4px;
  overflow: hidden;
`;

export const CoreStatsView = ({ isSection }: { isSection?: boolean }) => {
  const appId = useAppBeingEdited();
  const { properties } = useLocalAppProperties();
  const { getDesignProperty } = useAppTheme();
  const HighlightHEX = getDesignProperty('highlightHEX') as string;

  const coreStatsConfig = properties.CoreStats ? JSON.parse(properties.CoreStats) : { Enabled: '0' };
  const coreStats: CoreStat[] = coreStatsConfig ? coreStatsConfig.Stats : [];

  const getIconUrl = useCallback(
    (filename: string) => (isUrl(filename) ? filename : `${S3_BUCKET}${appId}/icons/${filename}`),
    [appId],
  );

  return (
    <CoreStatsSection $isSection={isSection}>
      {coreStats.map((stat, idx) => (
        <CoreStatWrapper key={idx} $isSection={isSection}>
          <CoreStatIcon>
            {stat.Icon && (
              <TintImage src={getIconUrl(stat.Icon)} color={HighlightHEX} dimensions={{ width: 24, height: 24 }} />
            )}
          </CoreStatIcon>
          {idx === 0 ? '3' : idx === 1 ? '2' : '54'}
          <CoreStatTitle>{stat.Title}</CoreStatTitle>
        </CoreStatWrapper>
      ))}
    </CoreStatsSection>
  );
};
