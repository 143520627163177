// Module IDs used by Builder
const MODULE_RECENTLY_WATCHED = 'RecentlyWatched';
export const MODULE_CORE_STATS = 'CoreStats';

export interface ModularFeatureSection {
  tooltip: string;
  thumbnail: string;
  name: string;
  defaultTemplate: number;
  featureTemplate: number;
  collectionProperty: { Id: string; Section?: string; ContentSection?: string };
}

// MFF Sections
export const MODULAR_FEATURE_SECTIONS: Record<string, ModularFeatureSection> = {
  [MODULE_RECENTLY_WATCHED]: {
    tooltip: "The ‘Recently Watched’ section will display the user's watched videos from the past 30 days.",
    thumbnail: 'slider_scroll.svg',
    name: 'Recently Watched',
    defaultTemplate: 3, // Default layout used when adding the section to the app
    featureTemplate: 801, // Fake template ID used by AddDrawer to identify the feature
    collectionProperty: { Id: MODULE_RECENTLY_WATCHED, ContentSection: MODULE_RECENTLY_WATCHED },
  },
  [MODULE_CORE_STATS]: {
    tooltip: "The ‘My Core Stats’ section will display the user's total and weekly video completion stats.",
    thumbnail: 'custom_section_core_stats.svg',
    name: 'My Core Stats',
    defaultTemplate: 3,
    featureTemplate: 802,
    collectionProperty: { Id: MODULE_CORE_STATS, Section: MODULE_CORE_STATS },
  },
};
