import { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
  CATALOG_SOURCE_ID,
  IN_PROGRESS_TEMPLATE,
  MIXED_TEMPLATE,
  MOST_WATCHED_7_DAYS_SOURCE_ID,
  MY_PRODUCTS_SOURCE_ID,
  SOURCE_VIDAPP,
} from 'api';
import {
  CollectionDescriptionPreview,
  CollectionTitle,
  ContentAdvancedSettings,
  ContentSourceTitle,
  DevTag,
} from 'components';
import { getCollectionName, getSourceTypeDisplayName, isUserAdmin, isUserDev } from 'utils';

import { UNTITLED_BLOCK_NAME } from 'app/modules/build-dragdrop/Builder/const';
import {
  CollectionCustomItemList,
  CollectionDisplayType,
  CollectionItemList,
  CollectionSource,
  CollectionTemplateDrawer,
  CollectionThumbnail,
  ContentResourceList,
  DrawerDeleteButton,
  DrawerFooter,
  DrawerHeading,
  DrawerScrollContainer,
  NoCollection,
  SectionDisplayType,
  Separator,
} from 'app/modules/build-dragdrop/Builder/drawer/components/index';
import { useInProgressItems } from 'app/modules/build-dragdrop/Builder/mockup/hooks';
import { useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { useSaveContext } from 'providers';
import { CustomSectionDrawer } from './CustomSectionDrawer';

const Content = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 32px;
`;

const DrawerFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SectionDetailsDrawer = () => {
  const { collections, setSectionTemplate } = useContent();
  const { drawerContext, visiblePageId } = useBuilderContext();
  const { setCollectionItemsToSave } = useSaveContext();

  // The Collection linked to this Block
  const collection = useMemo(() => {
    if (drawerContext?.itemId) {
      if (typeof drawerContext.itemId.startsWith('Temp')) {
        return collections[drawerContext.itemId];
      }
      return collections[parseInt(drawerContext.itemId)];
    }
    return undefined;
  }, [drawerContext?.itemId, collections]);

  const sourceType = collection ? getSourceTypeDisplayName(collection.SourceType, collection.DataSource) : 'Collection';

  // The CollectionItem for this Block
  const item = useMemo(() => {
    if (drawerContext?.tabItemId) {
      const pageCollection = visiblePageId && collections[visiblePageId];
      if (pageCollection) {
        return pageCollection.Items.find((item) => item.TabItemId?.toString() === drawerContext.tabItemId);
      }
    }
    return undefined;
  }, [drawerContext, visiblePageId, collections]);

  const parentCollection = item ? collections[item?.TabId] : undefined;
  const allowSectionDisplayEdit = parentCollection?.TemplateId === MIXED_TEMPLATE;

  useEffect(() => {
    if (!!collection && !!item) {
      if (collection.TemplateId === 30 && !item.TemplateId) {
        // If a collection set to 'Content - Mixed' has been added to a main tab through Build Advanced, convert it's block to Horizontal Scroll
        setSectionTemplate(item, 3);
        setCollectionItemsToSave(item.TabId);
      }
    }
  }, [!!collection, !!item]);

  // Retrieves all possible in progress items (course views) when this is an in progress section
  const isInProgress = [IN_PROGRESS_TEMPLATE].includes(item?.TemplateId || collection?.TemplateId || 0);
  const { items: inProgressItems } = useInProgressItems(collection?.TabId, isInProgress);

  if (!item) {
    console.warn('Unexpected error - SectionDetailsDrawer with no CollectionItem');
    return null;
  }

  let mffModule, customSection;
  try {
    mffModule = collection?.Properties?.Module ? JSON.parse(collection.Properties.Module) : null;
    customSection = mffModule?.Section;
  } catch (error) {
    mffModule = null;
    customSection = undefined;
  }

  const featureSection =
    isInProgress ||
    !!mffModule ||
    (collection &&
      [CATALOG_SOURCE_ID, MY_PRODUCTS_SOURCE_ID, MOST_WATCHED_7_DAYS_SOURCE_ID].includes(collection?.SourceId));

  const suffix = isUserDev() ? (
    <div>
      <DevTag>{collection?.TemplateId}</DevTag>
      <DevTag>{collection?.DataSource}</DevTag>
      <DevTag>{collection?.TabId}</DevTag>
      <DevTag>{item?.TabItemId}</DevTag>
    </div>
  ) : isUserAdmin() ? (
    <div>
      <DevTag label="V">{collection?.TabId}</DevTag>
      <DevTag label="S">{collection?.SourceId}</DevTag>
    </div>
  ) : null;

  return drawerContext?.showDisplayView ? (
    <CollectionTemplateDrawer collection={collection} item={item} />
  ) : drawerContext?.showCollectionDisplayView ? (
    <CollectionTemplateDrawer collection={collection} />
  ) : (
    <DrawerFlex>
      <DrawerHeading
        heading={collection ? getCollectionName(collection) : UNTITLED_BLOCK_NAME}
        type={
          <SpaceBetween>
            <div>Section</div>
            {suffix}
          </SpaceBetween>
        }
      />
      <DrawerScrollContainer paddingBottom="0">
        {!featureSection ? (
          <>
            {allowSectionDisplayEdit && <SectionDisplayType {...{ collection, item }} />}
            <Separator />
            <Content>
              {collection ? (
                <>
                  <CollectionTitle
                    size="middle"
                    TabId={collection.TabId}
                    Name={collection.Name}
                    sourceName={collection.SourceName}
                    $marginTop="0px"
                    $marginBottom="0px"
                    showDeepLink
                  />
                  {collection.DataSource !== SOURCE_VIDAPP && (
                    <>
                      <CollectionSource
                        SourceId={collection.SourceId}
                        DataSource={collection.DataSource}
                        SourceType={collection.SourceType}
                      />
                      <CollectionDisplayType
                        collection={collection}
                        tooltip={`This is the layout that will be seen by your app users when they open this ${sourceType} in your app.`}
                      />
                      <CollectionThumbnail collection={collection} />
                    </>
                  )}
                  {collection.DataSource === SOURCE_VIDAPP ? (
                    /* A custom vidapp section can have content added to it  */
                    <CollectionCustomItemList collection={collection} />
                  ) : (
                    <CollectionItemList Items={collection.Items} showAllItems />
                  )}
                  {collection.DataSource !== SOURCE_VIDAPP && (
                    <>
                      <CollectionDescriptionPreview collection={collection} />
                      <ContentResourceList Resources={collection?.Resources} />
                      <ContentSourceTitle
                        size="middle"
                        DataSource={collection.DataSource}
                        SourceName={collection.SourceName}
                      />
                    </>
                  )}
                  <ContentAdvancedSettings collection={collection} isDrawer isSection />
                </>
              ) : (
                <NoCollection />
              )}
            </Content>
          </>
        ) : customSection ? (
          <CustomSectionDrawer section={customSection} />
        ) : (
          <>
            {!isInProgress && (
              <>
                {allowSectionDisplayEdit && <SectionDisplayType {...{ collection, item }} />}
                <Separator />
              </>
            )}
            <Content>
              {collection ? (
                <>
                  <CollectionTitle
                    size="middle"
                    TabId={collection.TabId}
                    Name={collection.Name}
                    sourceName={collection.SourceName}
                    $marginTop="0px"
                    $marginBottom="0px"
                  />
                  {/* InProgress section does not have items */}
                  {isInProgress ? (
                    <CollectionItemList Items={inProgressItems} showAllItems overrideTitle="Course View Items" />
                  ) : !mffModule ? (
                    <CollectionItemList Items={collection.Items} showAllItems />
                  ) : null}
                </>
              ) : (
                <NoCollection />
              )}
            </Content>
          </>
        )}
      </DrawerScrollContainer>
      <DrawerFooter>
        {collections[item.TabId].DataSource === SOURCE_VIDAPP && <DrawerDeleteButton item={item} />}
      </DrawerFooter>
    </DrawerFlex>
  );
};
