import { MODULE_CORE_STATS } from 'mff';
import { CoreStatsView } from '../TabViews/components/CoreStatsView';

export const CustomSection = ({ section }: { section: string }) => {
  switch (section) {
    case MODULE_CORE_STATS:
      return <CoreStatsView isSection />;
    default:
      return null;
  }
};
