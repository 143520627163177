import { CoreStatsDrawerSettings } from 'app/modules/build-screens/TabDrawers/components/CoreStatsDrawerSettings';
import { MODULE_CORE_STATS } from 'mff';

export const CustomSectionDrawer = ({ section }: { section: string }) => {
  switch (section) {
    case MODULE_CORE_STATS:
      return <CoreStatsDrawerSettings isSection />;
    default:
      return null;
  }
};
