import { useCallback } from 'react';

import { SettingsImageInput, SettingsSegment, SettingsTextInput } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';
import { useAppBasicInfo } from 'hooks';

const SIDE_MENU_HEADER_LANDSCAPE_PROPERTY = 'SideMenuHeaderLandscapeWeb';
const SIDE_MENU_HEADER_SQUARE_PROPERTY = 'SideMenuHeaderSquareWeb';
const SIGN_UP_IMAGE_PROPERTY = 'SignUpImageWeb';
const SIGN_IN_IMAGE_PROPERTY = 'SignInImageWeb';

const IMAGE_INPUTS = [
  {
    title: 'Side Menu Header Logo (Landscape)',
    tooltip: 'This image will be displayed at the top of the Side Menu.',
    property: SIDE_MENU_HEADER_LANDSCAPE_PROPERTY,
    width: '177px',
    height: '38px',
    requiredDimensions: { requiredWidth: 350, requiredHeight: 75 },
    requirementsLabel: 'JPG/PNG. 350px x 75px.',
    scaleSpinner: 80,
  },
  {
    title: 'Side Menu Header Logo (Square)',
    tooltip: 'This image will be displayed at the top of the minimized Side Menu on smaller browser windows.',
    property: SIDE_MENU_HEADER_SQUARE_PROPERTY,
    width: '38px',
    height: '38px',
    requiredDimensions: { requiredWidth: 80, requiredHeight: 80 },
    requirementsLabel: 'JPG/PNG. 80px x 80px.',
    scaleSpinner: 80,
  },
  {
    title: 'Sign Up Image',
    tooltip:
      'This image will be displayed on your Sign Up screen. We recommend keeping important content between the grey dotted lines as the sides of the images will be cropped depending on the browser window size.',
    property: SIGN_UP_IMAGE_PROPERTY,
    width: '446px',
    height: '533px',
    verticalGuide: '285px',
    scaleSpinner: 10,
  },
  {
    title: 'Sign In Image (optional)',
    tooltip:
      "This image will be displayed on your Sign In screen. If you don't upload an image your Sign Up Image will be used instead. We recommend keeping important content between the grey dotted lines as the sides of the images will be cropped depending on the browser window size.",
    property: SIGN_IN_IMAGE_PROPERTY,
    width: '446px',
    height: '533px',
    verticalGuide: '285px',
    scaleSpinner: 10,
  },
];

export const WebAppSettings = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();
  const { data } = useAppBasicInfo();

  const handlePropertyChange = useCallback(
    (_filename, property) => {
      setAppProperty(property, _filename);
      setAppPropertyToSave(property, _filename);
    },
    [setAppProperty, setAppPropertyToSave],
  );

  return (
    <>
      <SettingsTextInput
        label="Web Title"
        name="WebTitle"
        placeholder={data?.AppleTitle}
        $marginBottom="40px"
        maxLength={30}
        tooltip="Affects the title shown in the browser tab, defaults to the iOS App Name"
        defaultValue={properties?.WebTitle}
        onChange={(e) => handlePropertyChange(e.target.value, 'WebTitle')}
      />
      {IMAGE_INPUTS.map(
        ({
          title,
          tooltip,
          property,
          width,
          height,
          requiredDimensions,
          requirementsLabel,
          verticalGuide,
          scaleSpinner,
        }) => (
          <SettingsSegment key={property} title={title} tooltip={tooltip}>
            <SettingsImageInput
              filename={properties[property]}
              requirementsLabel={requirementsLabel ?? 'JPG/PNG'}
              width={width}
              height={height}
              requiredDimensions={requiredDimensions}
              acceptedFileTypes="image/png, image/jpeg"
              onFilenameChange={(filename) => handlePropertyChange(filename, property)}
              scaleSpinner={scaleSpinner}
              verticalGuide={verticalGuide}
            />
          </SettingsSegment>
        ),
      )}
    </>
  );
};
