import { RefObject, useCallback } from 'react';
import styled from 'styled-components';

import { Collection, CollectionItem, TEXT_IMAGE_TEMPLATE, WEBSITE_TEMPLATE, WORKOUT_TEMPLATE_ID } from 'api';
import { getCollectionName } from 'utils';

import { AppCollectionContextProvider, useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import {
  CollectionCarousel,
  CollectionGridView,
  CollectionHorizontalRow,
  CollectionListView,
  CollectionTitle,
  CourseView,
  WebsiteView,
  WorkoutView,
} from '../components';
import { UNTITLED_BLOCK_NAME } from '../../const';
import { ContentThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { BuilderCollection, BuilderCollectionItem } from 'app/modules/build-dragdrop/Builder/providers';
import { CollectionInProgress } from './CollectionViews/CollectionInProgress';
import { CustomSection } from './CollectionViews/CustomSection';
import { HtmlPageView } from 'app/modules/build-dragdrop/Builder/mockup/components/CollectionViews/HtmlPageView';
import {
  CAROUSEL_TEMPLATES,
  COURSE_TEMPLATES,
  GRID_TEMPLATES,
  LIST_TEMPLATES,
  SLIDER_TEMPLATES,
} from 'app/modules/build-dragdrop/Builder/mockup/const';

export interface AppCollectionProps {
  templateId: number;
  collection?: BuilderCollection | Collection; // If no collection show a dummy of this template
  id?: string; // Element ID
  onClick?: (collection?: BuilderCollection | Collection) => void;
  isDetailsView?: boolean; // When viewed as CollectionDetails
  collectionRef?: RefObject<HTMLDivElement>;
  isDragDrop?: boolean;
  hideHeader?: boolean;
}

const getPadding = (templateId: number) => {
  if (SLIDER_TEMPLATES.includes(templateId)) {
    // Horizontal Row
    // Contain their own side padding for scrolling purposes
    return '12px 0';
  }
  if (CAROUSEL_TEMPLATES.includes(templateId)) {
    // Carousel Items have side padding
    // Carousel Dots have lower padding (for ease of clicking)
    return '8px 4px';
  }
  if (COURSE_TEMPLATES.includes(templateId)) {
    return '0';
  }
  return '12px 12px';
};

const CollectionWrapper = styled.div<{ templateId: number; isDragDrop?: boolean; disableDragDrop?: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  padding: ${({ templateId }) => getPadding(templateId)};
  border: ${({ isDragDrop, templateId }) => (isDragDrop || templateId === 39 ? 'none' : 'transparent solid 2px')};
  border-radius: ${({ templateId }) => (templateId === 39 ? '0px' : '12px')};
  height: ${({ templateId }) => [WEBSITE_TEMPLATE, WORKOUT_TEMPLATE_ID].includes(templateId) && '100%'};

  flex-shrink: 0;
  flex-grow: 0;

  cursor: ${({ disableDragDrop }) => (disableDragDrop ? 'unset' : 'pointer')};

  .add-button {
    display: none;
  }
`;

const StyledThumbnail = styled(ContentThumbnail)`
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
  margin-bottom: 8px;
`;

/**
 * A mockup render of a collection from the Content API
 * Specifically the view of the collection in a Context-Mixed View
 */
export const AppCollection = ({
  collection,
  onClick,
  templateId,
  isDetailsView,
  collectionRef,
  hideHeader,
  ...props
}: AppCollectionProps) => {
  const { setCurrentPage, disableDragDrop, navigationEnabled } = useMockupContext();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(collection);
    }
  }, [collection, onClick]);

  const handleItemClick = useCallback(
    (item: BuilderCollectionItem | CollectionItem) => {
      setCurrentPage({
        itemType: item.Type,
        itemId: item.ChildId,
        tabItemId: item.TabItemId,
        parentCollectionId: collection?.TabId,
      });
    },
    [setCurrentPage, collection],
  );

  const customSection = collection?.Properties?.Module ? JSON.parse(collection?.Properties?.Module).Section : undefined;

  return (
    <AppCollectionContextProvider
      collection={collection}
      onClick={handleClick}
      onItemClick={onClick || !navigationEnabled ? undefined : handleItemClick}
      templateId={templateId}
      isDetailsView={isDetailsView}
    >
      <CollectionWrapper
        templateId={templateId}
        ref={collectionRef}
        disableDragDrop={disableDragDrop}
        onClick={handleClick}
        {...props}
      >
        {customSection ? (
          <CustomSection section={customSection} />
        ) : SLIDER_TEMPLATES.includes(templateId) ? (
          <CollectionHorizontalRow />
        ) : CAROUSEL_TEMPLATES.includes(templateId) ? (
          <CollectionCarousel />
        ) : GRID_TEMPLATES.includes(templateId) ? (
          <CollectionGridView />
        ) : LIST_TEMPLATES.includes(templateId) ? (
          <CollectionListView hideHeader={hideHeader} />
        ) : 39 === templateId ? (
          <CourseView />
        ) : WEBSITE_TEMPLATE == templateId ? (
          <WebsiteView />
        ) : WORKOUT_TEMPLATE_ID == templateId ? (
          <WorkoutView />
        ) : TEXT_IMAGE_TEMPLATE === templateId ? (
          <HtmlPageView />
        ) : [44].includes(templateId) ? (
          <CollectionInProgress />
        ) : (
          <>
            <CollectionTitle title={collection ? `⚠️ ${getCollectionName(collection)}` : UNTITLED_BLOCK_NAME} />
            <StyledThumbnail thumbnail={collection || ({} as Collection)} size="medium" />
          </>
        )}
      </CollectionWrapper>
    </AppCollectionContextProvider>
  );
};
