import { Form } from 'antd';
import { useCallback } from 'react';

import { ContentAdvancedSettings, DrawerSettingsGroup, Segment, SettingsFormItem, SettingsTextInput } from 'components';
import { useContent, useLocalAppProperties } from 'providers';

import { useTabSettings } from 'app/modules/build-screens/hooks';
import { NavigationHeader, TabBar } from '../components';
import { TabDrawer } from '../components/Common/TabDrawer';
import { getPageName } from '../utils';
import { CoreStatsDrawerSettings } from './components/CoreStatsDrawerSettings';
import { DrawerSeparator } from './components/DrawerSeparator';

const PROFILE_LINKS = [
  {
    heading: 'Manage My Subscription',
    name: 'WebsiteSubscriptionLink',
    disabled: true,
  },
  {
    heading: 'Term & Conditions',
    name: 'TermsOfService',
  },
  {
    heading: 'Privacy Policy',
    name: 'PrivacyPolicy',
  },
  {
    heading: 'Support',
    name: 'AppSupportURL',
    placeholder: 'www.vidapp.com/support',
    tooltip: 'Users will use this link to submit support tickets. We advise using the VidApp link',
  },
];

interface ProfileTabDrawerProps {
  tabId: number;
}

export const ProfileTabDrawer = ({ tabId }: ProfileTabDrawerProps) => {
  const [form] = Form.useForm();
  const { collections } = useContent();
  const tab = collections[tabId];

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      WebsiteSubscriptionLink: { type: 'app', valueType: 'string' },
      TermsOfService: { type: 'app', valueType: 'string' },
      PrivacyPolicy: { type: 'app', valueType: 'string' },
      AppSupportURL: { type: 'app', valueType: 'string' },
    },
    tabId,
    form,
  );
  const { properties } = useLocalAppProperties();

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <TabDrawer tabId={tabId}>
      <Form
        name="settings-form"
        onValuesChange={(changedValues) => {
          onValuesChange(changedValues);
        }}
        autoComplete="off"
        form={form}
      >
        <NavigationHeader tabId={tabId} placeholder={getPageName(tab)} />
        {properties.DisplayCoreStats === '1' && (
          <>
            <DrawerSeparator />
            <Segment>
              <CoreStatsDrawerSettings />
            </Segment>
          </>
        )}
        <DrawerSeparator />
        <Segment header="Links">
          {PROFILE_LINKS.map((link) => (
            <DrawerSettingsGroup key={link.name} heading={link.heading} tooltip={link.tooltip}>
              <SettingsTextInput
                size="middle"
                placeholder={link.heading}
                value={link.heading}
                disabled={true}
                $marginBottom="4px"
              />
              <SettingsFormItem name={link.name}>
                <SettingsTextInput
                  size="middle"
                  placeholder={link.placeholder ?? 'www.example.com'}
                  disabled={link.disabled}
                />
              </SettingsFormItem>
            </DrawerSettingsGroup>
          ))}
        </Segment>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <TabBar filename={tab.Icon} placeholder={getPageName(tab)} onFilenameChange={onTabIconFilenameChange} />
      </Form>
    </TabDrawer>
  );
};
