import { useCallback } from 'react';
import styled from 'styled-components';
import { SettingsToggleInput, SettingsImageInput, SettingsFormItem, SettingsTextInput, SegmentTitle } from 'components';
import { useAppBeingEdited } from 'app-context';
import { CORE_STATS_NAME_MAX, CORE_STATS_ICON_WIDTH, CORE_STATS_ICON_HEIGHT } from '../const';

const CoreStatWrapper = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  height: 20px;
`;

interface CoreStatProps {
  heading: string;
  subheading: string;
  filename?: string;
  onInputChange: (id: string, value: string) => void;
  onFilenameChange: (_filename: string) => void;
  titleName: string;
}

const CoreStat = ({ heading, subheading, filename, onInputChange, onFilenameChange, titleName }: CoreStatProps) => {
  const appId = useAppBeingEdited();

  const onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onInputChange(e.currentTarget.id, e.currentTarget.value);
  };

  return (
    <CoreStatWrapper>
      <SegmentTitle title={heading} description={subheading} />
      <SettingsImageInput
        subheadingMinHeight="50px"
        requirementsLabel={`PNG, ${CORE_STATS_ICON_WIDTH} x ${CORE_STATS_ICON_HEIGHT}`}
        filePath={`${appId}/icons/`}
        width="50px"
        height="50px"
        requiredDimensions={{
          requiredWidth: CORE_STATS_ICON_WIDTH,
          requiredHeight: CORE_STATS_ICON_HEIGHT,
        }}
        acceptedFileTypes="image/png"
        filename={filename}
        onFilenameChange={onFilenameChange}
      />
      <Spacer />
      <SettingsFormItem name={titleName}>
        <SettingsTextInput
          size="middle"
          maxLength={CORE_STATS_NAME_MAX}
          placeholder={heading}
          onChange={onTitleChange}
        />
      </SettingsFormItem>
    </CoreStatWrapper>
  );
};

interface CoreStatsSettingsProps {
  filenames: Record<string, string | undefined>;
  onToggleChange: (checked: boolean) => void;
  onInputChange: (id: string, value: string) => void;
  onFilenameChange: (
    property: string,
    _filename: string,
    type: 'app' | 'collection' | 'collectionProperty' | 'coreStats',
  ) => void;
  isSection?: boolean;
}

export const CoreStatsSettings = ({
  filenames,
  onToggleChange,
  onInputChange,
  onFilenameChange,
  isSection,
}: CoreStatsSettingsProps) => {
  const onIcon1FilenameChange = useCallback(
    (_filename) => {
      onFilenameChange('CoreStat1Icon', _filename, 'coreStats');
    },
    [onFilenameChange],
  );

  const onIcon2FilenameChange = useCallback(
    (_filename) => {
      onFilenameChange('CoreStat2Icon', _filename, 'coreStats');
    },
    [onFilenameChange],
  );

  const onIcon3FilenameChange = useCallback(
    (_filename) => {
      onFilenameChange('CoreStat3Icon', _filename, 'coreStats');
    },
    [onFilenameChange],
  );

  return (
    <>
      {!isSection && (
        <SettingsToggleInput
          name="CoreStatsEnabled"
          label="Three Core Stats"
          description="This feature will not display in your app if it is switched off."
          marginBottom="48px"
          onInputChange={onToggleChange}
          showLeftToRight
        />
      )}
      <CoreStat
        heading="Completed This Week"
        subheading="The number of items completed in the current calendar week, Monday - Sunday."
        filename={filenames.CoreStat1Icon}
        onInputChange={onInputChange}
        onFilenameChange={onIcon1FilenameChange}
        titleName="CoreStat1Title"
      />
      <CoreStat
        heading="Average Per Week"
        subheading="The average number of items completed during a calendar week, based on the last 4 weeks."
        filename={filenames.CoreStat2Icon}
        onInputChange={onInputChange}
        onFilenameChange={onIcon2FilenameChange}
        titleName="CoreStat2Title"
      />
      <CoreStat
        heading="Total Completed"
        subheading="The total number of items that have been completed or marked as complete."
        filename={filenames.CoreStat3Icon}
        onInputChange={onInputChange}
        onFilenameChange={onIcon3FilenameChange}
        titleName="CoreStat3Title"
      />
    </>
  );
};
